import React from 'react';
import './geeksforgeeks.css';

const Geeksforgeeks = () => {
    return (
        <div className='geeksforgeeks__stats'>
          {/* Your existing content */}
          {/* <h1>Welcome to my App!</h1> */}
          {/* Render the UserStats component inside the root element */}
          {/* For example, you can pass the username as a prop */}
          {/* <UserStats username="iZnoGouDAf" /> */}
          <img src="https://geeks-for-geeks-stats-api-napiyo.vercel.app/?userName=parthsolanki1498"></img>
        </div>
    ); 
};
export default Geeksforgeeks;